import React from "react";
import "./Nav.css";
import logo from "./logo.png";
function Nav() {
  return (
    <div className="nav">
      <div className="nav__contents">
        <h3>Home</h3>
        <h3>Work</h3>
        <h3>Shop</h3>
        <img src={logo} alt="sattu_design" className="logo" />
        <h3>About</h3>
        <h3>Services</h3>
        <h3>Contact</h3>
      </div>
    </div>
  );
}

export default Nav;
