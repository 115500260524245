import React from "react";
import "./Banner.css";
import Nav from "./Nav";
function Banner() {
  return (
    <div className="banner">
      <Nav />
      <div className="banner__contents">
        <h1 className="fname">Satyendra</h1>
        <h1 className="lname">Bhattarai</h1>
        <p className="signature">Lifestyle & Design</p>
      </div>
    </div>
  );
}

export default Banner;
