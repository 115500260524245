import React from "react";
import "./MyWork.css";
import one from "./assets/1.png";
import two from "./assets/2.png";
import three from "./assets/3.png";
import four from "./assets/4.png";
import five from "./assets/5.png";
import Marquee from "react-fast-marquee";
function MyWork() {
  return (
    <div className="mywork">
      <div className="mywork__contents">
        <h1>My Work</h1>
        <div className="lines">
          <div className="circle"></div>
        </div>
        <div className="mywork__contentsMarquee">
          <Marquee play={true} gradient={false} speed={50} pauseOnHover={false}>
            <div className="track">
              <img src={one} alt="seedshop" srcset="" />
              <img src={two} alt="seedshop" srcset="" />
              <img src={three} alt="seedshop" srcset="" />
              <img src={five} alt="seedshop" srcset="" />
              <img src={four} alt="seedshop" srcset="" />
            </div>
          </Marquee>
          <button className="mywork__btn">More Work</button>
        </div>
      </div>
    </div>
  );
}

export default MyWork;
