import React from "react";
import "./HomeScreen.css";
import Banner from "./Banner";
import MyWork from "./MyWork";
function HomeScreen() {
  return (
    <div className="homescreen">
      <Banner />
      <MyWork />
    </div>
  );
}

export default HomeScreen;
